import { Dialog, Fab, Grid, Tooltip } from '@mui/material'
import React, { useState } from 'react'
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { CompraInfo } from './CompraInfo';
import { useNavigate } from 'react-router-dom';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios';
import { eliminarContrato, postReenviarNotificacion } from '../services/apis';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import logo from '../media/logoSage.png';


export const CompraEnviadaCard = ({ compra, bgColor }) => {

    const [open, setOpen] = useState(false);

    const navigate = useNavigate();

    const reenviarNotificacion = async (id) => {
        try {
            const response = await postReenviarNotificacion({
                id: id
            });
            console.log(response.data);
            if (response.data.status === 'success') {
                alert('Notificacion reenviada');
            } else {
                alert('Error al reenviar notificacion');
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <Grid container xs={12} justifyContent={'space-between'} padding={"1em"} direction={'row'} sx={{ backgroundColor: bgColor }}>
            <Grid item>
                Fecha Envio {compra.date}
            </Grid>
            <Grid item>
                Titulo: {compra.title}
            </Grid>
            <Grid item>
                Estado: {compra.status_name}
            </Grid>
            <Grid item>
                <strong>
                    Fecha Expiracion:&nbsp;
                </strong>
                {compra.expiration_date ? compra.expiration_date : "Sin expiracion"}
            </Grid>

            <Grid item>
                Nombre Archivo: {compra.file_name}
            </Grid>
            <Grid item>
                Fecha Envio{compra.date}
            </Grid>
            <Grid container justifyContent={"start"} gap={2} xs={12} marginTop={"0.2em"}>
                <Tooltip title="Visualizar Informacion del contrato">

                    <Fab size='small' onClick={() => setOpen(!open)}>
                        <PriorityHighIcon ></PriorityHighIcon>
                    </Fab>
                </Tooltip>
                {(compra.status_code == 'ENDED' || compra.status_code == 'SIGNED' || compra.status_code == 'REJECTED' || compra.status_code == 'CANCELED') && (
                    // <Tooltip title="Ver Contrato">
                    //     <Fab size='small' onClick={() => window.open('https://sandbox.docuten.com/ViewDocument.html?id=' + compra.id, '_blank')}>
                    //         <RemoveRedEyeIcon />
                    //     </Fab>
                    // </Tooltip>
                    <></>
                )}

                {(compra.status_code == 'PENDING' || compra.status_code == 'VALIDATING' || compra.status_code == 'IMPORTING') && (
                    <>
                        {(compra.status_code == 'VALIDATING') && (
                            <Tooltip title="Validar">

                                <Fab size='small' onClick={() => window.open('https://sandbox.docuten.com/ViewDocument.html?id=' + compra.id, '_blank')}>
                                    <DoneOutlineIcon />
                                </Fab>
                            </Tooltip>
                        )}
                        {(compra.status_code == 'IMPORTING') && (
                            <Tooltip title="Importar A Sage">
                                <Fab size="small" onClick={() => console.log("Logo clicked")}>
                                    <img style={{ maxWidth: "100%" }} src={logo} className='logo-header' />

                                </Fab>
                            </Tooltip>
                        )}

                        <Tooltip title="Reenviar Notificacion">

                            <Fab size='small' onClick={() => reenviarNotificacion(compra.id)}>
                                <ForwardToInboxIcon />
                            </Fab>
                        </Tooltip>
                        <Tooltip title="Eliminar/ Anular">

                            <Fab size='small' onClick={() => eliminarContrato(compra.id)}>
                                <DeleteForeverIcon />
                            </Fab>

                        </Tooltip>

                    </>
                )}
            </Grid>
            {
                open && (
                    <CompraInfo open={open} setOpen={setOpen} id={compra.id} status_code={compra.status_code}></CompraInfo>
                )
            }
        </Grid >
    )
}
