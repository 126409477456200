import React, { useEffect, useState } from 'react'
import { Header } from '../components/global/Header'
import axios from 'axios';
import { CompraCard } from '../components/compras/CompraCard';
import { useNavigate } from 'react-router-dom';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import { getCookie } from '../components/utils/cookies';
import { Grid } from '@mui/material';


export const VerCompras = () => {
	//global

	//util
	const [compras, setCompras] = useState([]);
	const [loaded, setLoaded] = useState(false);
	const wsUrl = "compras.clasol.com";
	const Navigate = useNavigate();
	const token = getCookie('PHPSESSID');
	//buscador
	const [browser, setBrowser] = useState('');
	const [searchTerm, setSearchTerm] = useState('');

	useEffect(() => {
		firstLoad();

	}, []);


	//buscador
	const handleSearch = (event) => {
		const searchTerm = event.target.value.toLowerCase();
		setSearchTerm(searchTerm);

		const filteredData = browser.filter((item) =>
			searchTerm
				.split(/\s+/)
				.every((word) => item.lista.toLowerCase().includes(word))
		);

		setCompras(filteredData);
	};
	const listar = (list) => {
		console.log(list)
		const newList = list.map((element) => ({
			...element,
			lista: `${element.IDCompra} ${element.DesRepresentante} ${element.Nombre} ${element.Cantidad} kg ${element.DesVariedad} ${element.Fecha} ${element.codigoPapel} ${element.NIF}`,
		}));

		console.log(newList);
		setBrowser(newList);
		setCompras(newList);
		setLoaded(true);
	};
	//buscador

	const firstLoad = () => {

		//cargar compras
		console.log("toke", token)
		axios.get('https://' + wsUrl + '/WS/compras.php?&firmada=0?&token=' + token)
			.then(function (response) {
				listar(response.data);
				console.log(response);
			})
			.catch(function (error) {
				console.log(error);

				console.error('Error en la solicitud:', error);
			})
			.finally(function () {
			})
	}
	return (
		<div>
			<Header />
			<div className='flex-left'>
				<div className='return-button' onClick={() => Navigate("/")}>
					<KeyboardReturnIcon />
					&nbsp;Volver
				</div>
			</div>
			<Grid container xs={12} gap={1} alignItems={'center'} justifyContent={'center'} direction={'row'}>

				<Grid container xs={10} gap={1} alignItems={'center'} justifyContent={'center'} direction={'row'} className='header-compras-list'>
					<Grid container xs={12} gap={1} alignItems={'center'} justifyContent={'center'} direction={'row'} >

						<p>Compras Enviadas</p>
					</Grid>

					<Grid container xs={12} gap={1} alignItems={'center'} justifyContent={'center'} direction={'row'} paddingBottom={'1em'}>
						<Grid container xs={1}>
							<SearchIcon sx={{ fontSize: 40 }} />
						</Grid>
						<Grid container xs={10}>
							<TextField className="textfield" value={searchTerm} disabled={!loaded} onChange={handleSearch} fullWidth label="Buscar" id="fullWidth" />
						</Grid>
					</Grid>

				</Grid>
			</Grid>
			{(loaded === false) && (
				<div className='flex-container spinner'>
					<div className='loading-spinner'>
					</div>
				</div>
			)}
			{loaded === true && (
				<Grid container xs={12} justifyContent={'center'}>
					{compras.length >= 1 ? (
						compras.map((compra, index) => (
							<Grid container xs={10.75} lg={3.5} key={index} className="compras-list-card">

								<CompraCard
									key={index}
									compra={compra}
									firmada={'Firmar'}
									setCompras={setCompras}
									compras={compras}
								/>
							</Grid>
						))
					) : (<>
						<div className="emptey-alert">
							Todavia No hay compras por firmar....
						</div>
						<div className='return-button' onClick={() => Navigate("/")}>
							<KeyboardReturnIcon />
							Vovler
						</div>
					</>
					)}
				</Grid>
			)}
		</div>
	)
}
