import {
    Box, Button, CircularProgress, Dialog, DialogActions, DialogContent,
    DialogTitle,
    Grid
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { getDocumento } from '../services/apis';

export const CompraInfo = ({ setOpen, open, id, status_code }) => {
    const [pdfUrl, setPdfUrl] = useState(null);
    const [loaded, setLoaded] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const cargarInfoCompra = async (id) => {
        try {
            const response = await getDocumento(id, status_code);

            // Asume que "response.pdf.data.signed" es tu base64
            if (status_code === 'ENDED' || status_code === 'VALIDATING') {
                setPdfUrl(pdfConvert(response.pdf.data.signed));
            } else if (status_code === 'PENDING' || status_code === 'REJECTED' || status_code === 'INVALIDATE' || status_code === 'EXPIRED' || status_code === 'CANCELED' ) {
                setPdfUrl(pdfConvert(response.pdf.data.original));
            }
        } catch (error) {
            console.log(error);
        }
    };

    const pdfConvert = (base64) => {
        const binaryString = atob(base64);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        const blob = new Blob([bytes], { type: 'application/pdf' });
        setLoaded(true);
        return URL.createObjectURL(blob);

    };

    useEffect(() => {
        if (open && id) {
            cargarInfoCompra(id);
        }
    }, [open, id]);

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            // Elimina fullScreen o asegúrate de que sea false
            fullScreen={false}
            // Con fullWidth y maxWidth="lg" ocupas más espacio, pero no 100% 
            fullWidth
            maxWidth="lg"
            // Opcional: si quieres forzar una altura
            PaperProps={{
                sx: {
                    height: '80vh'  // ajusta a tu gusto
                }
            }}
        >
            <DialogTitle>Información Compra</DialogTitle>

            <DialogContent>
                <Box sx={{ width: '100%', height: '100%' }}>
                    {!loaded && (
                        <Grid container justifyContent={'center'} direction={'column'} minHeight={'50vh'} alignContent={'center'} xs={12}>
                            <Grid container xs={12} justifyContent={'center'}>
                                <CircularProgress />
                            </Grid>
                            <Grid container justifyContent={'center'} alignContent={'center'}>
                                Cargando Contrato...
                            </Grid>
                        </Grid>
                    )}
                    {loaded && (
                        <>
                            {pdfUrl && (
                                <>
                                    <iframe
                                        src={pdfUrl}
                                        width="100%"
                                        height="100%"
                                        title="PDF"
                                        style={{ border: 'none' }}
                                    />
                                    <p>
                                        <a href={pdfUrl} download="documento.pdf">
                                            Descargar PDF
                                        </a>
                                    </p>
                                </>
                            )}
                        </>
                    )}
                </Box>
            </DialogContent>

            <DialogActions>
                <Button variant="contained" onClick={handleClose}>
                    Cerrar
                </Button>
            </DialogActions>
        </Dialog>
    );
};
