import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios';

import { Header } from '../components/global/Header'
import LogoutIcon from '@mui/icons-material/Logout';
import { getCookie } from '../components/utils/cookies';
import { getopcinesMenu } from '../services/apis';
import { Button, CircularProgress, Grid } from '@mui/material';

//mapa de iconos
const iconMapping = {

};

export const MainMenu = () => {
	const navigate = useNavigate();

	//global
	const [loaded, setLoaded] = useState(false);

	// Estados útiles
	const [opciones, setOpciones] = useState([]);
	//
	const Navigate = useNavigate();

	const token = getCookie('PHPSESSID');
	const [option, setOption] = useState();


	const cerrarSesion = async () => {


		localStorage.removeItem('token');
		localStorage.removeItem('username');
		localStorage.removeItem('code');
		localStorage.removeItem('mail');
		localStorage.removeItem('DesRepresentante');


		Navigate("/login");

		try {
			const response = await axios.post('https://compras.clasol.com/WS/Logout.php', {
				'token': token,
			}, {
				headers: {
					'Content-Type': 'application/json',
				},
			});
			console.log('Respuesta del servidor:', response.data);


		} catch (error) {
			console.error('Error al invalidar el token', error);
		}
	};
	// const rolVerf = async () => {
	// 	try {
	// 		const response = await axios.post('https://compras.clasol.com/WS/MenuOptions.php', {
	// 			'token': token,
	// 		}, {
	// 			headers: {
	// 				'Content-Type': 'application/json',
	// 			},
	// 		});
	// 		setOption(response.data.optionmenu);
	// 	} catch (error) {
	// 		console.error('Error al invalidar el token', error);
	// 	}
	// };

	const cargarOpciones = async () => {
		try {
			const response = await getopcinesMenu();
			console.log("aa", response)
			setOpciones(response.data)
			setLoaded(true);
		} catch (error) {
			console.error("Error al añadir el cliente:", error);
		}
	};
	useEffect(() => {
		cargarOpciones();
		// rolVerf();
	}, [])
	return (
		<>
			<Header />
			{!loaded && (
				<CircularProgress />
			)}
			<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>

				<div className="vertical-menu" style={{ width: '100%', maxWidth: '400px' }}>
					{loaded && (
						<Grid container spacing={2}>
							{opciones.map(opcion => (
								<Grid item xs={12} key={opcion.id}>
									<div
										className="main-menu-button"
										style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '10px', cursor: 'pointer' }}
										onClick={() => navigate(opcion.url)}
									>
										{iconMapping[opcion.icon]} {/* Assuming `iconMapping` renders a valid icon */}
										<span style={{ marginLeft: '10px' }}>
											{opcion.nombre_opcion.toUpperCase()}
										</span>
									</div>
								</Grid>
							))}
						</Grid>
					)}
				</div>
			</div>
		</>
		// </div>
	)
}
