import React, { useEffect, useState } from 'react'
import { getListaDocumentos, getopcinesCompras } from '../services/apis';
import { useNavigate } from 'react-router-dom';
import { getCookie } from '../utils/cookies';
import { CircularProgress, Grid } from '@mui/material';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import { Header } from '../components/global/Header';
import { CompraEnviadaCard } from './CompraEnviadaCard';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';

export const VerEnviadas = () => {
    //util
    const [comprasEnviadas, setComprasEnviadas] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [browser, setBrowser] = useState('');
    const wsUrl = "compras.clasol.com";
    const Navigate = useNavigate();
    const token = getCookie('PHPSESSID');
    const [loaded, setLoaded] = useState(false);

    // Objeto con colores según estado
    const statusColors = {
        REJECTED: "#e95555",  // rojo claro
        EXPIRED: "#f2f2f2",  // gris claro
        PENDING: "#fff8c6",  // amarillo claro
        CANCELED: "#ffd8d8",  // rosa claro
        INVALIDATE: "#f5d8ff", // lila claro
        ENDED: "#d8ffd8",   // verde claro
        VALIDATING: "#9ec6ec",   // verde claro
        IMPORTING: "#d48bff"   // verde claro


    };

    const cargarOpcionesFirmadas = async () => {
        try {
            const response = await getopcinesCompras();
            console.log("Opciones del menú: ", response.data);
        } catch (error) {
            console.log(error);
        }
    };

    const cargarComprasEnviadas = async () => {
        try {
            const response = await getListaDocumentos();
            if (response.success === true) {
                setComprasEnviadas(response.data.documents);
                listar(response.data.documents);

                setLoaded(true);
            }
            console.log("Documentos: ", response.data);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        cargarComprasEnviadas();
        cargarOpcionesFirmadas();
    }, []);
    const handleSearch = (event) => {
        const searchTerm = event.target.value.toLowerCase();
        setSearchTerm(searchTerm);

        const filteredData = browser.filter((item) =>
            searchTerm
                .split(/\s+/)
                .every((word) => item.lista.toLowerCase().includes(word))
        );

        setComprasEnviadas(filteredData);
    };
    const listar = (list) => {
        console.log(list)
        const newList = list.map((element) => ({
            ...element,
            lista: `${element.IDCompra} ${element.DesRepresentante} ${element.Nombre} ${element.Cantidad} kg ${element.DesVariedad} ${element.Fecha} ${element.codigoPapel} ${element.NIF}`,
        }));

        console.log(newList);
        setBrowser(newList);
        setComprasEnviadas(newList);
        setLoaded(true);
    };

    return (
        <div>
            <Header />
            <Grid container xs={12} justifyContent={'center'}>
                <Grid container xs={12} justifyContent={'start'}>
                    <div className='return-button' onClick={() => Navigate("/")}>
                        <KeyboardReturnIcon />
                        &nbsp;Volver
                    </div>
                </Grid>
                <Grid container xs={12} gap={1} justifyContent={'center'} direction={'row'}>

                    <>

                        {/* Si no se han cargado los datos, mostramos un mensaje */}
                        <Grid container xs={10} gap={1} alignItems={'center'} justifyContent={'center'} direction={'row'} className='header-compras-list'>
                            <Grid container xs={12} gap={1} alignItems={'center'} justifyContent={'center'} direction={'row'} >

                                <p>Compras Enviadas </p>
                            </Grid>

                            <Grid container xs={12} gap={1} alignItems={'center'} justifyContent={'center'} direction={'row'} paddingBottom={'1em'}>
                                <Grid container xs={1}>
                                    <SearchIcon sx={{ fontSize: 40 }} />
                                </Grid>
                                <Grid container xs={10}>
                                    <TextField className="textfield" value={searchTerm} disabled={!loaded} onChange={handleSearch} fullWidth label="Buscar" id="fullWidth" />
                                </Grid>
                            </Grid>

                        </Grid>
                        <Grid container xs={10} gap={1} alignItems={'center'} justifyContent={'center'} direction={'row'} >
                            {!loaded && <CircularProgress />}
                        </Grid>
                        {/* Si se han cargado los datos, mostramos las compras */}
                        {loaded && comprasEnviadas.length >= 0 && (
                            <>
                                {comprasEnviadas.map((compra, index) => {
                                    // Determinamos el color de fondo según el status
                                    const bgColor = statusColors[compra.status_code] || '#ffffff';
                                    return (
                                        <Grid
                                            container
                                            direction={'row'}
                                            justifyContent={'center'}
                                            xs={11}
                                            lg={2}
                                            key={index}
                                            style={{ backgroundColor: bgColor }} // <-- aquí aplicamos el color
                                        >
                                            <CompraEnviadaCard compra={compra} />
                                        </Grid>
                                    );
                                })}
                            </>
                        )}

                    </>

                </Grid>
            </Grid>
        </div >
    );
};
